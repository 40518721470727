@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@media print {
  .sidebar_wrapper,
.header_wrapper,
.print_btn {
    display: none;
  }
}
.print_view .MuiTableContainer-root {
  overflow: hidden !important;
}

/* Hover Transistion */
/* Custom scroll Bar */
/* Custom scroll Bar Inner */
/* Custom scroll Bar Inner */
/*
!Utilities Or Helper CSS
*/
*,
::before,
::after {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  background-color: #f8f8f8;
}

html,
body {
  overflow-x: hidden;
}

p {
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  color: black;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  word-wrap: break-word;
}

img,
svg {
  max-width: 100%;
}

a {
  color: unset;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

a,
span {
  display: inline-block;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

:focus {
  outline: none !important;
}

input,
select,
textarea {
  font-weight: 400;
  font-size: 16px;
  color: #101010;
}

input:focus,
select:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #4790c7 !important;
}

textarea:focus {
  box-shadow: none !important;
  outline: none !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

button {
  cursor: pointer;
  border: none !important;
  background-color: transparent;
}

button:hover {
  border: none !important;
}

*::-moz-placeholder {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #707070;
}

*:-ms-input-placeholder {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #707070;
}

*::placeholder {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #707070;
}

*::-moz-placeholder {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #707070;
}

::-moz-selection {
  color: rgb(255, 255, 255);
  background: rgb(159, 4, 255);
}

::selection {
  color: rgb(255, 255, 255);
  background: rgb(159, 4, 255);
}

::-moz-selection {
  color: rgb(255, 255, 255);
  background: rgb(159, 4, 255);
}

/*  Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Common Class */
.d-flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.g-sm {
  gap: 8px 12px;
}

.g-lg {
  gap: 10px 15px;
}

.header_area {
  margin-bottom: 20px;
}

.page_title {
  font-size: 22px;
  font-weight: 500;
  color: black;
}

.symptoms_title {
  font-size: 20px !important;
  font-weight: 400 !important;
  color: black !important;
  margin-bottom: 20px !important;
}
@media all and (max-width: 991px) {
  .symptoms_title {
    transition: 0.5s;
    font-size: 18px !important;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 30;
  display: none;
}
.overlay.outside_overlay {
  display: block;
}

.main_content_area {
  margin-top: 80px;
  margin-bottom: 40px;
  margin-left: 270px;
  margin-right: 30px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
@media all and (max-width: 991px) {
  .main_content_area {
    transition: 0.5s;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.sidebar_toggle_active {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.sidebar_toggle_active .main_content_area {
  margin-left: 30px;
}

.search_tabl_form_area {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-bottom: 20px;
}
@media all and (min-width: 600px) {
  .search_tabl_form_area {
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    max-width: 250px;
  }
}
.search_tabl_form_area form {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
}

.select__control input:focus {
  border: none !important;
}

.select__menu {
  z-index: 10;
}

.manage_view_wrapper td {
  font-size: 15px;
}

@media print {
  .sidebar_wrapper,
.header_wrapper,
.print_btn {
    display: none;
  }
}
.print_view .MuiTableContainer-root {
  overflow: hidden !important;
}

/* Hover Transistion */
/* Custom scroll Bar */
/* Custom scroll Bar Inner */
/* Custom scroll Bar Inner */
.login_wrapper {
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  background-color: #7cabda;
  overflow: hidden;
}
.login_wrapper .login_grid_area {
  position: relative;
  z-index: 10;
}
.login_wrapper .login_grid_area h3,
.login_wrapper .login_grid_area p {
  color: white;
}
.login_wrapper .login_grid_area .input_row {
  border: 1px solid white;
}
.login_wrapper .login_grid_area ::-moz-placeholder {
  color: white;
}
.login_wrapper .login_grid_area :-ms-input-placeholder {
  color: white;
}
.login_wrapper .login_grid_area ::placeholder,
.login_wrapper .login_grid_area input,
.login_wrapper .login_grid_area label {
  color: white;
}

.dashboar_page_wrapper .overview_item {
  padding: 20px 10px;
  color: white;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.dashboar_page_wrapper .overview_item:hover {
  box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.2), 1px 2px 4px 0px rgba(0, 0, 0, 0.3), 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}
.dashboar_page_wrapper .overview_item .MuiAvatar-root {
  font-size: 35px;
}
.dashboar_page_wrapper .overview_item .MuiListItemText-primary {
  font-size: 24px;
  font-weight: 500;
  color: black;
}
.dashboar_page_wrapper .overview_item .MuiListItemText-secondary {
  font-size: 20px;
  font-weight: 600;
  color: black;
}
.dashboar_page_wrapper .dashboard_chart_wrapper {
  margin-top: 30px;
}
.dashboar_page_wrapper .chart_item_wrapper {
  padding: 20px;
}
.dashboar_page_wrapper .chart_item_wrapper .chart_header_area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px 5px;
  min-height: 56px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
@media all and (max-width: 575px) {
  .dashboar_page_wrapper .chart_item_wrapper .chart_header_area {
    transition: 0.5s;
    align-items: start;
    flex-direction: column;
  }
  .dashboar_page_wrapper .chart_item_wrapper .chart_header_area .basic-single {
    width: 100%;
  }
}
.dashboar_page_wrapper .chart_item_wrapper h3 {
  font-size: 18px;
  font-weight: 500;
}
.dashboar_page_wrapper .chart_body_area {
  position: relative;
  margin-top: 15px;
}
.dashboar_page_wrapper #genderArea {
  width: 100%;
  height: 400px;
}
.dashboar_page_wrapper .pie_label_list {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 24px;
}
.dashboar_page_wrapper .pie_label_list li {
  position: relative;
  color: #0088FE;
}
.dashboar_page_wrapper .pie_label_list li::before {
  content: " ";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
  width: 12px;
  height: 9px;
  background-color: #0088FE;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.dashboar_page_wrapper .pie_label_list .male_lable {
  color: #00C49F;
}
.dashboar_page_wrapper .pie_label_list .male_lable::before {
  background-color: #00C49F;
}

.add_page_wrapper {
  padding: 10px;
  background: white;
}
.add_page_wrapper .tab_prev_next_button_area {
  margin: 30px 20px;
}
.add_page_wrapper .select__control {
  min-height: 40px;
}
.add_page_wrapper .group_checkbox_area {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.add_page_wrapper .group_checkbox_area .pulse_input {
  display: flex;
  align-items: center;
  gap: 5px;
}
.add_page_wrapper .group_checkbox_area .group_check_input .MuiInputBase-input {
  padding: 5px !important;
  width: 70px;
}
.add_page_wrapper .title_radio_btn_area {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}
.add_page_wrapper .title_radio_btn_area .header_area {
  margin-bottom: 0;
}

@media all and (min-width: 992px) {
  .patient_view_wrapper {
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    padding: 10px;
  }
}

.patient_view_wrapper {
  padding: 20px;
  background: white;
}
.patient_view_wrapper .page_title {
  font-size: 20px;
  font-weight: 500;
}
.patient_view_wrapper table.MuiTable-root {
  margin-bottom: 20px;
}
.patient_view_wrapper .middle_divied_table tr td:not(:last-child) {
  border-right: 1px solid rgb(224, 224, 224);
}
.patient_view_wrapper th {
  font-size: 18px;
  font-weight: 500;
}
.patient_view_wrapper td {
  font-size: 16px;
  font-weight: 400;
}

.staff_header_area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 7px 12px;
  margin-bottom: 20px;
}
.staff_header_area .header_area {
  margin-bottom: 0;
}
.staff_header_area .staff_btn {
  font-size: 16px;
  font-weight: 500;
  color: white;
  padding: 10px 20px;
  background-color: #7cabda;
}
.staff_header_area .staff_btn:hover {
  opacity: 0.8;
}

/* Not Found Section */
.not_found_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: calc(100vh - 121px);
  background-color: #0bb49d;
  /*************swing************/
  /*************swing hair************/
}
.not_found_wrapper #handboy {
  -webkit-animation: swing ease-in-out 1.3s infinite alternate;
          animation: swing ease-in-out 1.3s infinite alternate;
  transform-origin: 98% 98%;
  transform-box: fill-box;
}
.not_found_wrapper #girllight {
  -webkit-animation: swing ease-in-out 1.3s infinite alternate;
          animation: swing ease-in-out 1.3s infinite alternate;
  transform-origin: 0% 97%;
  transform-box: fill-box;
}
.not_found_wrapper #hairgirl {
  -webkit-animation: swinghair ease-in-out 1.3s infinite alternate;
          animation: swinghair ease-in-out 1.3s infinite alternate;
  transform-origin: 60% 0%;
  transform-box: fill-box;
}
.not_found_wrapper #zero {
  transform-origin: bottom;
  transform-box: fill-box;
}
@-webkit-keyframes swing {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
@keyframes swing {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
@-webkit-keyframes swinghair {
  0% {
    transform: rotate(6deg);
  }
  100% {
    transform: rotate(-6deg);
  }
}
@keyframes swinghair {
  0% {
    transform: rotate(6deg);
  }
  100% {
    transform: rotate(-6deg);
  }
}/*# sourceMappingURL=App.css.map */