@import "../../style/mixins";
@import "../../style/variable";
.sidebar_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  z-index: 500;
  padding: 10px;
  padding-top: 80px;
  background-color: $white-color;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  @include hover-transition();
  @include customScrollbarTable();
  @include max-media-width(991) {
    left: -100%;
  }
  &.sidebar_toggle_active {
    left: -100%;
  }

  .menu_list_wrapper {
    .MuiListItemIcon-root {
      min-width: 40px;
    }
  }

  .accordion_wrapper {
    a {
      width: 100%;
    }
    [aria-expanded="true"] {
      .MuiAccordionSummary-content {
        .MuiListItemIcon-root {
          svg path {
            fill: $blue-color-00;
          }
        }
      }
      .MuiListItemText-root {
        .MuiTypography-body1 {
          color: $blue-color-00;
        }
      }
    }
    .active {
      svg path,
      svg circle {
        fill: $blue-color-00;
      }
      .MuiListItemText-primary {
        color: $blue-color-00;
      }
    }
    .MuiPaper-root {
      box-shadow: none;
      background-color: transparent;
      &::before {
        display: none;
      }
    }
    .MuiAccordionSummary-root {
      padding: 0;
    }
    .MuiAccordionSummary-content {
      margin: 2px 0;
    }
    .Mui-expanded {
      margin: 2px 0;
    }
  }
}
.sidebar_toggle_active {
  .sidebar_wrapper {
    left: -100%;
    @include max-media-width(991) {
      left: 0;
    }
  }
}
