@import "./variable";
$breakpoints: (
  "xl": 1399px,
  "lg": 1199px,
  "md": 991px,
  "sm": 767px,
  "xs": 575px,
  "xxs": 460px,
  "exs": 360px,
);

@mixin media($media) {
  @media all and (max-width: #{map-get($breakpoints,#{$media})}) {
    @content;
  }
}

@mixin media-break-width($media-width-min, $media-width-max) {
  @media (min-width: #{$media-width-min}px) and (max-width: #{$media-width-max}px) {
    transition: 0.5s; // animate when window resizing
    @content;
  }
}
@mixin max-media-width($media-width) {
  @media all and (max-width: #{$media-width}px) {
    transition: 0.5s; // animate when window resizing
    @content;
  }
}
@mixin min-media-width($media-width) {
  @media all and (min-width: #{$media-width}px) {
    transition: 0.5s;
    // animate when window resizing      @content    :;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    @content;
  }
}
@media print {
  .sidebar_wrapper,
  .header_wrapper,
  .print_btn {
    display: none;
  }
}
.print_view {
  .MuiTableContainer-root {
    overflow: hidden !important;
  }
}
/* Hover Transistion */
@mixin hover-transition {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

/* Custom scroll Bar */
@mixin customScrollbar {
  overflow-y: scroll;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(153, 153, 153, 0.2);
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #f84f4f;
    cursor: grab;
  }
}
/* Custom scroll Bar Inner */
@mixin customScrollbarInner {
  overflow-y: scroll;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(153, 153, 153, 0.2);
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #f84f4f;
    cursor: grab;
  }
}
/* Custom scroll Bar Inner */
@mixin customScrollbarTable {
  overflow: auto;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(153, 153, 153, 0.2);
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #f84f4f;
    cursor: grab;
  }
}

@mixin Background-Image {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

// ========== Extends ===========
