@import "../style/mixins";
@import "../style/variable";
.header_wrapper {
  display: grid;
  grid-template-columns: minmax(0, 220px) minmax(0, 100px) repeat(
      1,
      minmax(0, 1fr)
    );
  align-items: center;
  gap: 10px;
  position: fixed;
  top: 0;
  left: 0;
  padding: 8px 30px;
  width: 100%;
  z-index: 501;
  border-bottom: 1px solid $gray-color-d3;
  background-color: $white-color;
  @include max-media-width(991) {
    grid-template-columns: minmax(0, 60px) minmax(0, 40px) repeat(
        1,
        minmax(0, 1fr)
      );
    padding: 8px 20px;
  }
  @include max-media-width(767) {
    padding: 4px 20px;
  }
.logo_area{
  img{
    max-height: 40px;
  }
}
  .profile_user_area {
    text-align: end;
    .user_img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}
.header_fixed{
  box-shadow: 15px 6px 6px #eee9e985;
  backdrop-filter: saturate(50%) blur(8px);
  background-color: rgba($white-color, 0.7);
}
