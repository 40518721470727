@import "./mixins";
@import "./variable";

/*
!Utilities Or Helper CSS
*/
//Font
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
*,
::before,
::after {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  background-color: $gray-color;
}
html,
body {
  overflow-x: hidden;
}

p {
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  color: $black-color;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  word-wrap: break-word;
}
img,
svg {
  max-width: 100%;
}

a {
  color: unset;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}
a,
span {
  display: inline-block;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

:focus {
  outline: none !important;
}
input,
select,
textarea {
  font-weight: 400;
  font-size: 16px;
  color: #101010;
}
input:focus,
select:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #4790c7 !important;
}

textarea:focus {
  box-shadow: none !important;
  outline: none !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

button {
  cursor: pointer;
  border: none !important;
  background-color: transparent;
}

button:hover {
  border: none !important;
}

*::placeholder {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #707070;
}

*::-moz-placeholder {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #707070;
}
::selection {
  color: rgb(255, 255, 255);
  background: rgb(159, 4, 255);
}
::-moz-selection {
  color: rgb(255, 255, 255);
  background: rgb(159, 4, 255);
}

// Hide input number type right icon

/*  Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Common Class */

.d-flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.g-sm {
  gap: 8px 12px;
}
.g-lg {
  gap: 10px 15px;
}
.header_area{
  margin-bottom: 20px;
}
.page_title{
  font-size: 22px;
  font-weight: 500;
  color: $black-color;
}
.symptoms_title{
  font-size: 20px !important;
  font-weight: 400 !important;
  color: $black-color !important;
  margin-bottom: 20px !important;
  @include max-media-width(991) {
    font-size: 18px !important;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 30;
  display: none;
  &.outside_overlay {
    display: block;
  }
}

.main_content_area {
  margin-top: 80px;
  margin-bottom: 40px;
  margin-left: 270px;
  margin-right: 30px;
  @include hover-transition();
  @include max-media-width(991) {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.sidebar_toggle_active{
  @include hover-transition();
  .main_content_area{

    margin-left: 30px;
  }
}

//Search Table Form
.search_tabl_form_area{
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-bottom: 20px;
  @include min-media-width(600) {
    max-width: 250px;
  }

  form{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;
  }

}

//Remove focus caret
.select__control {
  input{
    &:focus{
      border: none !important;
    }
  }
}
.select__menu{
  z-index: 10;
}

.manage_view_wrapper{
  td{
    font-size: 15px;
  }
}





