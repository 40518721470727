@media print {
  .sidebar_wrapper,
.header_wrapper,
.print_btn {
    display: none;
  }
}
.print_view .MuiTableContainer-root {
  overflow: hidden !important;
}

/* Hover Transistion */
/* Custom scroll Bar */
/* Custom scroll Bar Inner */
/* Custom scroll Bar Inner */
.sidebar_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  z-index: 500;
  padding: 10px;
  padding-top: 80px;
  background-color: white;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.sidebar_wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.sidebar_wrapper::-webkit-scrollbar-track {
  background: rgba(153, 153, 153, 0.2);
  border-radius: 5px;
}
.sidebar_wrapper::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 5px;
}
.sidebar_wrapper::-webkit-scrollbar-thumb:hover {
  background: #f84f4f;
  cursor: -webkit-grab;
  cursor: grab;
}
@media all and (max-width: 991px) {
  .sidebar_wrapper {
    transition: 0.5s;
    left: -100%;
  }
}
.sidebar_wrapper.sidebar_toggle_active {
  left: -100%;
}
.sidebar_wrapper .menu_list_wrapper .MuiListItemIcon-root {
  min-width: 40px;
}
.sidebar_wrapper .accordion_wrapper a {
  width: 100%;
}
.sidebar_wrapper .accordion_wrapper [aria-expanded=true] .MuiAccordionSummary-content .MuiListItemIcon-root svg path {
  fill: #0088FE;
}
.sidebar_wrapper .accordion_wrapper [aria-expanded=true] .MuiListItemText-root .MuiTypography-body1 {
  color: #0088FE;
}
.sidebar_wrapper .accordion_wrapper .active svg path,
.sidebar_wrapper .accordion_wrapper .active svg circle {
  fill: #0088FE;
}
.sidebar_wrapper .accordion_wrapper .active .MuiListItemText-primary {
  color: #0088FE;
}
.sidebar_wrapper .accordion_wrapper .MuiPaper-root {
  box-shadow: none;
  background-color: transparent;
}
.sidebar_wrapper .accordion_wrapper .MuiPaper-root::before {
  display: none;
}
.sidebar_wrapper .accordion_wrapper .MuiAccordionSummary-root {
  padding: 0;
}
.sidebar_wrapper .accordion_wrapper .MuiAccordionSummary-content {
  margin: 2px 0;
}
.sidebar_wrapper .accordion_wrapper .Mui-expanded {
  margin: 2px 0;
}

.sidebar_toggle_active .sidebar_wrapper {
  left: -100%;
}
@media all and (max-width: 991px) {
  .sidebar_toggle_active .sidebar_wrapper {
    transition: 0.5s;
    left: 0;
  }
}/*# sourceMappingURL=sidebar.css.map */