//Import File
@import "./style/comon";
@import "./style/mixins";
@import "./style/variable";

//Login Page
.login_wrapper {
  position: relative;
  @include Background-Image();
  width: 100vw;
  height: 100vh;
  background-color: $blue-color-7c;
  overflow: hidden;
  .login_grid_area {
    position: relative;
    z-index: 10;
    h3,
    p {
      color: white;
    }
    .input_row {
      border: 1px solid white;
    }
    ::placeholder,
    input,
    label {
      color: white;
    }
  }
}

//Dashboard Page
.dashboar_page_wrapper {
  .overview_item {
    padding: 20px 10px;
    color: $white-color;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    background-color: $white-color;
    @include hover-transition();
    &:hover{
      box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.2),
      1px 2px 4px 0px rgba(0, 0, 0, 0.3), 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
    }
.MuiAvatar-root{
  font-size: 35px;
}
    .MuiListItemText-primary {
      font-size: 24px;
      font-weight: 500;
      color: $black-color;
    }
    .MuiListItemText-secondary {
      font-size: 20px;
      font-weight: 600;
      color: $black-color;
    }
  }
  .dashboard_chart_wrapper {
    margin-top: 30px;
  }
  .chart_item_wrapper {
    padding: 20px;
    .chart_header_area {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px 5px;
      min-height: 56px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba($black-color, 0.2);
      @include max-media-width(575) {
        align-items: start;
        flex-direction: column;
        .basic-single {
          width: 100%;
        }
      }
    }
    h3 {
      font-size: 18px;
      font-weight: 500;
    }
  }
  .chart_body_area {
    position: relative;
    margin-top: 15px;
  }
  #genderArea {
    width: 100%;
    height: 400px;
  }
  .pie_label_list {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 24px;
    li {
      position: relative;
      color: $blue-color-00;
      &::before {
        content: " ";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -17px;
        width: 12px;
        height: 9px;
        background-color: $blue-color-00;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
      }
    }
    .male_lable{
      color: $green-color-00;
      &::before{
        background-color: $green-color-00;
      }
    }
  }
}

//Add Patient Page
.add_page_wrapper {
  padding: 10px;
  background: $white-color;


  .tab_prev_next_button_area {
    margin: 30px 20px;
  }
  .select__control {
    min-height: 40px;
  }

  .group_checkbox_area {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    .pulse_input {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    .group_check_input {
      .MuiInputBase-input {
        padding: 5px !important;
        width: 70px;
      }
    }
  }

  .title_radio_btn_area {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    .header_area {
      margin-bottom: 0;
    }
  }
}
//Manage Patient Page
.patient_view_wrapper {
  @include min-media-width(992) {
    padding: 10px;
  }
}
//View Patient Page
.patient_view_wrapper {
  padding: 20px;
  background: $white-color;
  .page_title {
    font-size: 20px;
    font-weight: 500;

  }
  table.MuiTable-root {
    margin-bottom: 20px;
  }
  .middle_divied_table {
    tr {
      td {
        &:not(:last-child) {
          border-right: 1px solid rgba(224, 224, 224, 1);
        }
      }
    }
  }
  th {
    font-size: 18px;
    font-weight: 500;
  }
  td {
    font-size: 16px;
    font-weight: 400;
  }
}

//Staff Page
.staff_header_area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 7px 12px;
  margin-bottom: 20px;
  .header_area {
    margin-bottom: 0;
  }
  .staff_btn {
    font-size: 16px;
    font-weight: 500;
    color: $white-color;
    padding: 10px 20px;
    background-color: $blue-color-7c;
    &:hover {
      opacity: 0.8;
    }
  }
}

/* Not Found Section */
.not_found_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: calc(100vh - 121px);
  background-color: #0bb49d;

  #handboy {
    animation: swing ease-in-out 1.3s infinite alternate;
    transform-origin: 98% 98%;
    transform-box: fill-box;
  }

  #girllight {
    animation: swing ease-in-out 1.3s infinite alternate;
    transform-origin: 0% 97%;
    transform-box: fill-box;
  }

  #hairgirl {
    animation: swinghair ease-in-out 1.3s infinite alternate;
    transform-origin: 60% 0%;
    transform-box: fill-box;
  }

  #zero {
    transform-origin: bottom;
    transform-box: fill-box;
  }

  /*************swing************/
  @keyframes swing {
    0% {
      transform: rotate(10deg);
    }
    100% {
      transform: rotate(-10deg);
    }
  }

  /*************swing hair************/
  @keyframes swinghair {
    0% {
      transform: rotate(6deg);
    }
    100% {
      transform: rotate(-6deg);
    }
  }
}
