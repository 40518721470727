@media print {
  .sidebar_wrapper,
.header_wrapper,
.print_btn {
    display: none;
  }
}
.print_view .MuiTableContainer-root {
  overflow: hidden !important;
}

/* Hover Transistion */
/* Custom scroll Bar */
/* Custom scroll Bar Inner */
/* Custom scroll Bar Inner */
.header_wrapper {
  display: grid;
  grid-template-columns: minmax(0, 220px) minmax(0, 100px) repeat(1, minmax(0, 1fr));
  align-items: center;
  gap: 10px;
  position: fixed;
  top: 0;
  left: 0;
  padding: 8px 30px;
  width: 100%;
  z-index: 501;
  border-bottom: 1px solid #d3d3d3;
  background-color: white;
}
@media all and (max-width: 991px) {
  .header_wrapper {
    transition: 0.5s;
    grid-template-columns: minmax(0, 60px) minmax(0, 40px) repeat(1, minmax(0, 1fr));
    padding: 8px 20px;
  }
}
@media all and (max-width: 767px) {
  .header_wrapper {
    transition: 0.5s;
    padding: 4px 20px;
  }
}
.header_wrapper .logo_area img {
  max-height: 40px;
}
.header_wrapper .profile_user_area {
  text-align: end;
}
.header_wrapper .profile_user_area .user_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}

.header_fixed {
  box-shadow: 15px 6px 6px rgba(238, 233, 233, 0.5215686275);
  -webkit-backdrop-filter: saturate(50%) blur(8px);
          backdrop-filter: saturate(50%) blur(8px);
  background-color: rgba(255, 255, 255, 0.7);
}/*# sourceMappingURL=header.css.map */